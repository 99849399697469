import {css} from 'linaria';
import React, {MouseEvent, useEffect, useState, useRef} from 'react';

import {logCourseClick} from '../analyticsLogger';
import {State as CalcState} from '../constants';
import {
  SuggestedCourse,
  RawSuggestedCourse,
} from '../types/SuggestedCourseTypes';
import {Subject} from '../types';
import {ResultsData} from '../types/ResultTypes';
import {table} from '../sharedStyles';

type Props = {
  currentYear: number;
  resourcesURL: string | null;
  results: ResultsData | null;
  state: CalcState;
  subjects: ReadonlyArray<Subject>;
};

const BROWSER_SUPPORTS_CORS = 'withCredentials' in new XMLHttpRequest();
// Number of subjects required before suggested courses are displayed
const MIN_SUBJECTS = 4;
const SUGGESTION_URL = 'https://uninotes.com/api/courses';

const boostATARClass = css`
  background-color: #3a87ad;
  border: 1px solid #3a87ad;
  clear: both;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 8px 35px 8px 14px;
  text-align: center;

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

export default function SuggestedCourses(props: Props) {
  const courses = useSuggestedCourses(props);
  if (courses.length === 0) {
    return null;
  }

  return (
    <>
      {props.resourcesURL !== null && (
        <div className={boostATARClass}>
          &gt;&gt;&gt;&gt; WANT TO BOOST YOUR ATAR? CHECK OUT THESE{' '}
          <a href={props.resourcesURL}>FREE RESOURCES</a> &lt;&lt;&lt;&lt;
        </div>
      )}
      <div className="jsd_calculator_recommended">
        <h2 className="title mb-0 text-lg mb-1 mb-1">
          Relevant courses based on your ATAR calculation
        </h2>
        <table className={table}>
          <thead>
            <tr>
              <th>University</th>
              <th>Course</th>
              <th>Indicative ATAR</th>
            </tr>
          </thead>
          <tbody>
            {courses.map(course => (
              <SuggestedCourseItem
                course={course}
                key={course.university + '-' + course.name}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

const itemLinkClass =
  'text-gray-600 hover:text-black text-right transform transition duration-200';

function SuggestedCourseItem({course}: {course: SuggestedCourse}) {
  return (
    <tr className={course.highlighted ? 'highlighted' : ''}>
      <td>
        <a className={itemLinkClass} href={course.url} onClick={onCourseClick}>
          {course.university}
        </a>
      </td>
      <td>
        <a className={itemLinkClass} href={course.url} onClick={onCourseClick}>
          {course.name}
        </a>
      </td>
      <td>{course.atar}</td>
    </tr>
  );
}

function onCourseClick(evt: MouseEvent<HTMLAnchorElement, any>) {
  const url = evt.currentTarget.href;
  logCourseClick(url);
  window.open(url);
  evt.preventDefault();
}

function useSuggestedCourses({
  currentYear,
  results,
  state,
  subjects,
}: Props): ReadonlyArray<SuggestedCourse> {
  if (!BROWSER_SUPPORTS_CORS) {
    // Browser doesn't support CORS, not much we can do here.
    return [];
  }

  const [suggestedCourses, setSuggestedCourses] = useState<
    ReadonlyArray<SuggestedCourse>
  >([]);
  const [latestAtar, setLatestAtar] = useState<number | null>(null);
  const lastRequest = useRef<XMLHttpRequest | null>(null);

  function abortPendingRequest() {
    if (lastRequest.current != null) {
      lastRequest.current.onload = () => {};
      lastRequest.current.abort();
    }
  }

  useEffect(() => {
    const subjectCount = subjects.length;
    if (subjectCount < MIN_SUBJECTS) {
      // We have less than the minimum number of subjects required to load
      // suggested subjects, so just display nothing.
      setSuggestedCourses([]);
      return;
    }

    const atar =
      results &&
      results.years &&
      results.years[currentYear] &&
      +results.years[currentYear].enter;
    if (!atar) {
      // Current year doesn't have a proper ATAR estimate.
      setSuggestedCourses([]);
      return;
    }
    if (atar === latestAtar) {
      // Current data (or current request) is already for the same ATAR, don't
      // need to do anything.
      return;
    }

    // If we already have some cached data, filter it by the new ATAR score
    // (so at least *something* relevant is shown while new data loads)
    setSuggestedCourses(suggestedCourses.filter(course => course.atar <= atar));

    // Abort any current request, as it's no longer needed
    abortPendingRequest();

    const url = `${SUGGESTION_URL}?acs=${atar}&calc_state=${getCourseState(
      state,
    )}`;
    const request = new XMLHttpRequest();

    request.open('GET', url, true);
    request.onload = () => {
      const response = JSON.parse(request.responseText);
      setSuggestedCourses(formatData(response));
      lastRequest.current = null;
    };
    setLatestAtar(atar);
    lastRequest.current = request;
    request.send();

    return abortPendingRequest;
  }, [currentYear, results, state, subjects]);

  return suggestedCourses;
}

function formatData(
  data: ReadonlyArray<RawSuggestedCourse>,
): ReadonlyArray<SuggestedCourse> {
  // Simplify the format a bit, and convert ATAR score to a number
  return data.map(course => ({
    atar: +course['Minimum Atar Score'],
    highlighted: course['Highlighted'] === 'true',
    name: course['Course Name'],
    university: course['University Name'],
    url: course['External Course Link'],
  }));
}

function getCourseState(state: CalcState): string {
  switch (state) {
    case CalcState.VCE:
      return 'vic';
    case CalcState.HSC:
      return 'nsw';
    case CalcState.WACE:
      return 'wa';
    case CalcState.QCE:
      return 'qld';
    case CalcState.SACE:
      throw new Error('Not implemented');
  }
}
