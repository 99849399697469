// Alpine is required by some ATAR Notes stuff.
import Alpine from 'alpinejs';
import React, {useMemo} from 'react';
import {
  init as sentryInit,
  ErrorBoundary as SentryErrorBoundary,
} from '@sentry/react';

import {logAddSubject, logChangeYear} from '../analyticsLogger';
import {Props as CalculatorProps} from '../components/CalculatorCore';
import SuggestedCourses from '../components/SuggestedCourses';
import useCalculatorInputReducer, {
  ActionType as InputActionType,
} from '../hooks/useCalculatorInputReducer';
import useCalculatorURL from '../hooks/useCalculatorURL';
import useCalculationResult from '../hooks/useCalculationResult';
import {AppConfig} from '../types';
import {isBrowserTooOld} from '../utils/Detect';

type Props = {
  appConfig: AppConfig;
  calculator: React.ComponentType<CalculatorProps>;
};

/**
 * Container for the desktop version of ATAR Calculator. Renders the subject
 * selector and results.
 */
let CalculatorContainer = function ({
  appConfig,
  calculator: Calculator,
}: Props) {
  const [input, dispatchForInput] = useCalculatorInputReducer(appConfig);
  useCalculatorURL(appConfig, input, dispatchForInput);
  const [results, isLoading] = useCalculationResult(input);
  const availableSubjects = useMemo(() => {
    const selectedSubjectIDs = new Set(input.subjects.map(x => x.ID_SUBJECT));
    return appConfig.availableSubjects.filter(
      x => !selectedSubjectIDs.has(x.ID_SUBJECT),
    );
  }, [appConfig.availableSubjects, input.subjects]);

  return (
    <>
      {appConfig.alertMessage && (
        <p className="alert" id="beta_alert">
          {appConfig.alertMessage}
        </p>
      )}
      <Calculator
        availableSubjects={availableSubjects}
        currentYear={appConfig.currentYear}
        data={results}
        extraData={input.extraData}
        isLoading={isLoading}
        selectedYear={input.selectedYear}
        state={appConfig.state}
        subjects={input.subjects}
        yearsToShow={appConfig.yearsToShow}
        onAddSubject={subject => {
          logAddSubject(subject.name);
          dispatchForInput({type: InputActionType.ADD_SUBJECT, subject});
        }}
        onChangeExtraData={(field, value) =>
          dispatchForInput({
            type: InputActionType.CHANGE_EXTRA_DATA,
            field,
            value,
          })
        }
        onDeleteSubject={id =>
          dispatchForInput({type: InputActionType.DELETE_SUBJECT, id})
        }
        onFocusScore={focusScore}
        onSelectedYearChanged={year => {
          logChangeYear(year);
          dispatchForInput({type: InputActionType.CHANGE_YEAR, year});
        }}
        onUpdateSubject={(id, fields) =>
          dispatchForInput({type: InputActionType.UPDATE_SUBJECT, id, fields})
        }
      />
      {appConfig.bugReportMessage && (
        <p className="mt-4">{appConfig.bugReportMessage}</p>
      )}
      {appConfig.showSuggestedCourses && (
        <SuggestedCourses
          currentYear={appConfig.currentYear}
          resourcesURL={appConfig.resourcesURL}
          results={results}
          state={appConfig.state}
          subjects={input.subjects}
        />
      )}
    </>
  );
};

if (isBrowserTooOld) {
  CalculatorContainer = function () {
    return (
      <div className="errors">
        Sorry, your browser is no longer supported by its developer, and is too
        old for the ATAR Calculator. Please{' '}
        <a href="https://browsehappy.com/">update your browser</a> to a newer
        version of a common web browser such as Chrome, Firefox, Safari, Opera,
        or Edge.
      </div>
    );
  };
}

export default function CalculatorContainerWrapper(
  props: Props,
): React.ReactElement {
  return (
    <SentryErrorBoundary
      fallback={errorData => (
        <>
          <p>Sorry, an error occurred: {errorData.error?.message}.</p>
          <p>
            Please contact us at calc@atarnotes.com and let us know what went
            wrong.{' '}
            {errorData.eventId && (
              <>
                Include <strong>error ID {errorData.eventId}</strong> in your
                email.
              </>
            )}
          </p>
        </>
      )}>
      <CalculatorContainer {...props} />
    </SentryErrorBoundary>
  );
}

if (!__DEV__ || location.search.includes('enable_error_logging')) {
  sentryInit({
    autoSessionTracking: false,
    dsn: 'https://025b8c96f2781e4a210e594e2c8dfa67@errors.d.sb/3',
    debug: __DEV__,
    environment: __DEV__ ? 'development' : 'production',
    tracesSampleRate: 0.0,
    tunnel: '/error',
  });
}
Alpine.start();

/**
 * Focus on a particular score field
 */
function focusScore({id}: {id: number}) {
  // Terrible hack, I should really do this properly with React.
  window.setTimeout(() => {
    const el = document.getElementById('score_' + id);
    if (el) {
      el.focus();
    }
  }, 10);
}
