import React from 'react';
import {State} from '../constants';
import ATAR from './ATAR';

import Spinner from '../../img/spinner.gif';

type Props = {
  aggregate: string;
  atar: string;
  isLoading: boolean;
  state: State;
};

export default class Estimate extends React.Component<Props> {
  render() {
    return (
      <div className="estimate">
        <span className="estimate_description">Estimated ATAR: </span>
        <span className="estimated_atar">
          <ATAR atar={this.props.atar} />
          {this.props.isLoading ? (
            <img alt="Loading..." className="loading" src={Spinner} />
          ) : (
            <span className="loading" />
          )}
        </span>
        {this.props.state === State.WACE || this.props.state === State.QCE ? (
          <div>
            <span className="estimate_description">Scaled TEA: </span>
            <span className="scaled_aggregate">{this.props.aggregate}</span>
          </div>
        ) : null}
      </div>
    );
  }
}
