import React from 'react';
import {table} from '../sharedStyles';
import {SubjectFields} from '../types';
import {SubjectResult} from '../types/ResultTypes';
import {getLabel as getLetterGradeLabel} from '../utils/LetterGradeUtils';

type SubjectWithInput = {
  result: SubjectResult;
  fields: SubjectFields | undefined;
};

type Props = {
  scaledTitle: string | null;
  showDesc: boolean;
  showRaw?: boolean;
  subjects: ReadonlyArray<SubjectWithInput>;
  yearsToShow: ReadonlyArray<number>;
};

export default class ResultsSubjects extends React.Component<Props> {
  render() {
    if (this.props.subjects.length === 0) {
      return null;
    }

    return (
      <table
        className={
          table +
          (this.props.yearsToShow.length === 1
            ? ' subject_results_one'
            : ' subject_results')
        }>
        <thead>
          <tr>
            <th>Subject</th>
            {this.props.showDesc ? <th>Desc.</th> : null}
            {this.props.showRaw ? <th>Raw</th> : null}
            {this.props.yearsToShow.length === 1 ? (
              <th style={{width: '3em'}}>{this.props.scaledTitle}</th>
            ) : (
              this.props.yearsToShow.map(year => (
                <th key={year} style={{width: '3em'}}>
                  {this.props.scaledTitle} ({year})
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>{this.props.subjects.map(this._renderSubject, this)}</tbody>
      </table>
    );
  }

  _renderSubject(subject: SubjectWithInput) {
    const {result} = subject;
    // Don't show anything if the selected year has no data for this subject
    if (
      this.props.yearsToShow.length === 1 &&
      !result.scaled[this.props.yearsToShow[0]]
    ) {
      return null;
    }

    return (
      <tr key={result.id}>
        <td>{result.name}</td>
        {this.props.showDesc ? <td>{result.desc}</td> : null}
        {this.props.showRaw ? <td>{getRawScore(subject)}</td> : null}
        {this.props.yearsToShow.map(year => (
          <td key={year}>{result.scaled[year]}</td>
        ))}
      </tr>
    );
  }

  static defaultProps = {
    scaledTitle: 'Scaled',
    showRaw: true,
  };
}

function getRawScore(subject: SubjectWithInput): React.ReactNode | null {
  const {result, fields} = subject;
  if (result.score != null && result.score !== 0) {
    return result.score;
  }

  // If the server didn't return data for raw score, perhaps it's using a letter grade.
  if (fields != null && fields.grade != null) {
    return getLetterGradeLabel(fields.grade);
  }

  return null;
}
